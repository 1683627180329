.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  width: 100%;
  flex-shrink: 0;
  background-color: #1C1C21;
  box-sizing: border-box;
}

.navpanel {
  display: flex;
  width: 100%;
  max-width: 1280px;
  justify-content: space-between;
  align-items: center;
}

.container_left {
  display: flex;
  width: 30%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.container_right {
  composes: container_left;
  justify-content: flex-end;
}
