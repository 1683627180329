.filling {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-radius: 40px;
  background-color: #2F2F37;
}

.bun_top {
  composes: filling;
  border-top-left-radius: 88px;
  border-top-right-radius: 88px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
}

.bun_bottom {
  composes: filling;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 88px;
  border-bottom-left-radius: 88px;
}
