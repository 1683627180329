.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  position: absolute;
  top: 0;
  left: 0;
  /* box-sizing: border-box;
  z-index: 1;
  margin: auto; */
  /* border: 1px solid yellow; */
  overflow: hidden;
}

.content_container {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  outline: none;
  background-color: #1C1C21;
  border-radius: 40px;
  padding: 40px 40px 60px 40px;
  z-index: 10;
  margin: auto;
}

.title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: 640px;
}
