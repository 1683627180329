.card {
  display: flex;
  flex: none;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: #1C1C21;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
  z-index: 1;
}

.name {
  text-decoration: none;
}

.card :hover {
  cursor: pointer;
}

.name_div {
  overflow: hidden;
}

.components_price_box {
  display: flex;
  flex-direction: row;
  flex: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.price {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}

.images_div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: 0;
  padding-left: 20px;
}

.image_box {
  margin-left: -20px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #131316;
  border-radius: 50%;
  border: 2px solid #4C4CFF;
  position: relative;
}

.qty {
  position: absolute;
}

.image_opacity {
  opacity: 40%;
}

.number_time_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time {
  color: #8585AD;
}
