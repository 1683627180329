.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: auto;
  overflow: visible;
  box-sizing: border-box;
}

.section_content {
  display: flex;
  flex-wrap: nowrap;
  gap: 40px;
  overflow: hidden;
}
