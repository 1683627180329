.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 20px;
}

.icon {
  margin: 0 8px 0 0;
}

.caption {
  text-decoration: none;
  margin-left: 8px;
}

.caption_active {
  color: #F2F2F3;
}

.navlink {
  text-decoration: none;
}
