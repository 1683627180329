.container_category {
  overflow: visible;
}

.container_ingredients {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: flex-start;
  padding: 0 0 0 16px;
}

.title {
  padding: 40px 0 24px 0;
}

