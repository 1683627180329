.section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  width: 600px;
  height: calc(100vh - 88px);
  flex: none;
  box-sizing: border-box;
  padding: 40px 16px 40px 16px;
}

.section_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  box-sizing: content-box;
}

.container {
  display: flex;
  flex: none;
  width: 100%;
  justify-content: flex-end;
  align-items: stretch;
  box-sizing: border-box;
}

.container_misc {
  display: flex;
  /* padding добавлен для выравнивания, т.к. кнопка дает margin 56px который непонятно как убрать */
  padding: 0 0 56px 0;
  align-items: center;
}

.container_bun {
  composes: container;
  padding-left: 24px;
  margin-right: 16px;
}

.container_price {
  composes: container;
  margin-top: 24px;
  gap: 40px;
}
