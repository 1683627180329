.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text_error {
  white-space: pre-line;
  text-align: center;
}

.number_glow {
  text-shadow: 0 0 16px rgba(255, 51, 51, 0.25), 0 0 8px rgba(255, 51, 51, 0.25), 0 4px 32px rgba(255, 51, 51, 0.25);
}
