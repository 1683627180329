.panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh -88px);
  width: 100%;
  padding: 48px 0 0 0;
}

.subpanels {
  display: flex;
  max-height: 200px;
  column-gap: 36px;
  align-items: stretch;
  overflow: hidden;
}

.subpanel {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.numbers_panel {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 20px;
  overflow: hidden;
}

.text_order_done {
  color: #00CCCC;
}

.digit_shadow {
  text-shadow: 0px 0px 16px rgba(51, 51, 255, 0.25), 0px 0px 8px rgba(51, 51, 255, 0.25), 0px 4px 32px rgba(51, 51, 255, 0.5);
}
