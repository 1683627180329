.dashboard {
  display: flex;
  flex-direction: row;
  gap: 60px;
  width: 100%;
  max-width: 1240px;
  height: calc(100vh - 88px);
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 20px;
}
