.section {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ul {
  display: flex;
  gap: 20px;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
