.userOrdersFeed_section {
  width: 100%;
  max-height: calc(100vh - 88px - 40px);
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 24px;
  overflow: hidden;
  overflow-y: scroll;
}

.preloader_div {
  margin: auto;
}
