ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

.grey {
  color: #8585AD;
}

.white {
  color: white;
}

.link {
  text-decoration: none;
  color: #4C4CFF;
}

.profile {
  width: 100%;
  height: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  gap: 60px;
}

.section_menu {
  margin-top: 120px;
  max-width: 320px;
}
