.section {
  display: flex;
  flex-direction: column;
  width: 600px;
  box-sizing: border-box;
  padding: 40px 0 10px 0;
}

.section_ingredients {
  overflow-y: scroll;
}

.div_tabs {
  display: flex;
}
