.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number_glow {
  text-shadow: 0 0 16px rgba(51, 51, 255, 0.25), 0 0 8px rgba(51, 51, 255, 0.25), 0 4px 32px rgba(51, 51, 255, 0.5);
}

.preloader {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader_circle {
  display: block;
  width: 170px;
  height: 170px;
  border: 5px solid;
  border-color: #d1d2d6 #9fa0a5 #626368 #1a1b22;

  border-radius: 50%;

  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  animation: spin 0.75s infinite linear;
}

/* .preloader_burger {
  animation: spin 0.75s infinite linear;
} */

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
