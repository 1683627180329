.overlay {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, .6);
}
