.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number_glow {
  text-shadow: 0 0 16px rgba(51, 51, 255, 0.25), 0 0 8px rgba(51, 51, 255, 0.25), 0 4px 32px rgba(51, 51, 255, 0.5);
}

.icon_accepted {
  height: 120px;
}
